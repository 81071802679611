import  React from "react";
import { useLocation } from 'react-router-dom'
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import {
  FooterBackground,
  CompanyInformation,
  List,
  TneedLogo,
  TotalList,
  TextPosition,
  SocialIcon,
  SocialRef,
  Instagram,
  FullRow,
  Column,
  SocialAlign,
} from "src/components/Footer/Style";
import Logo from "src/components/Logo";



const Footer: React.FC = () => {
  const location = useLocation();
  return (
    <Container fluid={true}  style={{display: location.pathname ==='/contact-us' ? 'none' : 'block' }}>
      <FullRow  className="wow fadeInLeft animated" >
        <FooterBackground lg={7} md={7} xl={7} sm={6}></FooterBackground>
        <CompanyInformation xs={12} lg={4} md={4} xl={4} sm={6} className="distance">
          <SocialAlign className="wow fadeInRight animated" >
          <TotalList >
            {/* <TextPosition>
              <List>Address:</List>
              <List>Canada-Vancouver</List>
            </TextPosition> */}
            <TextPosition>
              <List>Email:</List>
              <List>hello@tneed.com</List>
            </TextPosition>
            <List>
              <SocialRef href="">
                <SocialIcon className="fab fa-linkedin-in"></SocialIcon>
              </SocialRef>
              <SocialRef href="">
                <SocialIcon className="instagram-border">
                  <Instagram className="fab fa-instagram"></Instagram>
                </SocialIcon>
              </SocialRef>
              <SocialRef href="">
                <SocialIcon className="fab fa-facebook-f"></SocialIcon>
              </SocialRef>
              <SocialRef href="">
                <SocialIcon className="fab fa-twitter"></SocialIcon>
              </SocialRef>
            </List>
          </TotalList>
          <TneedLogo>
            <Logo />
          </TneedLogo>
          </SocialAlign>
        </CompanyInformation>

        <Column lg={1} md={1} xl={1}></Column>
      </FullRow>
    </Container>
  );
};

export default Footer;
