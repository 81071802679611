import styled from 'styled-components';
import { HeaderCircle, } from "src/components/Designs";

import JobsBack from "public/assets/images/jobs.png";



export const Span=styled.p`
color:#f05a22;
font-weight: 700;
margin-top: 65px;
--max-size: 18;
--min-size: 14;
font-size: var(--responsive)
`;

export const DutyList=styled.ul`
padding-left: 17px;

`;
export const ListItem=styled.li`
  
    color:#00AAC0;
`;


export const Div=styled.div`
margin-bottom:30px;

`;

export const MainDiv=styled.div`
width: 50%;
margin-right: auto;
margin-left: auto;
display: flex;
flex-direction: column;
align-items: center;
text-align: center;

@media(max-width:992px){
    width:80%;
}
`;

export const Section=styled.section`
padding-top:170px;
@media(max-width:414px){
    padding-top:110px;
}
`;

export const CareerList=styled.div`
margin-top: 110px ;
padding: 0px 100px;
@media(max-width:992px){
    padding:0;
}
@media(max-width:768px){
    margin-top: 80px ;
}
@media(max-width:576px){
    margin-top: 60px ;
    padding: 0 52px;
}
@media(max-width:375px){
    padding: 0 20px;
}


.any-border{
    border:none;
}
`;

export const CareerItem=styled.div`
padding: 20px 0px;
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
border-bottom: 1px solid #F05A22;
--max-size: 18;
--min-size: 14;
font-size: var(--responsive);
@media(max-width:576px){
    padding: 10px 0px;
}
`;

export const ItemName=styled.p`
color:#FFFFFF;
margin-bottom:0;

`;

export const Figure=styled.figure`
margin-bottom:0px;
`;

export  const FigureCaption=styled.figcaption`
color:#707070;

`;

export const MainContainer=styled.div`
background-image: url(./assets/images/maincareer.png);
background-size: contain;
background-repeat: no-repeat;
background-position: right;
height: 100vh;
@media(max-width:1024px){
    height: 64vh;
}
@media(max-width:992px){
    height: 100vh;
}
@media(max-width:768px){
    height: 60vh;
}
@media(max-width:576px){
    height: 100vh;
}
@media(max-width:414px){
    height: 70vh;
}
@media(max-width:320px){
    height: 100vh;
}

`;

export const CirclePosition = styled(HeaderCircle)`
  ::before {
    bottom: 7px;
    right: 180px;
    @media(max-width:1024px){
        right: 149px;
    }
    @media(max-width:768px){
        right: 122px;
    }
    @media(max-width:576px){
        right: 87px;
    }
    @media(min-width:1920px){
     left:0;    
    }
`;

export const JobCircle=styled(HeaderCircle)`
::before {
    bottom: 3px;
`;


export const FullScreen=styled.div`
min-height: 100vh;
background-image: url(${JobsBack});
background-size: contain;
background-repeat: no-repeat;
background-position: right;
`;

