import React from "react";
import {
  FullScreen,
  Section,
  Span,
  Div,
  DutyList,
  ListItem,
  JobCircle
} from "src/pages/career/style";
import { ParagraphText, TextHeader,} from "src/components/Designs";
import Example from "src/components/modal/index";
import { Container } from "react-bootstrap";

const SoftwarePage: React.FC = () => {
  return (
    <>
      <Section>
        <FullScreen>
          <Container>
            <JobCircle>
            <Div>
              <TextHeader>Software engineering</TextHeader>
            </Div>
            </JobCircle>
            
            <Div>
              <ParagraphText>
                OldRobo has a positive, diverse, and supportive culture. we look
                for people who are curious, inventive, and work
              </ParagraphText>
              <ParagraphText>
                to be a little better every single day. In our work together we
                aim to be smart, humble, hardworking and, above all,
              </ParagraphText>
              <ParagraphText>
                collaborative. If this sounds like a good fit for you, why not
                say hello?
              </ParagraphText>
            </Div>

            <Div>
              <Span>Staff Frontend Performance Engineer</Span>
              <ParagraphText>
                OldRobo is looking for a Software Engineer with experience in
                behind-the-scenes JavaScript to get the job done. We love
                engineers
              </ParagraphText>
              <ParagraphText>
                who are more passionate about reading stack traces. We love
                performance optimization, writing unit tests, improving
                maintainability, and iterating on best-practices with the rest
                of the frontend teams. communication and collaboration skills
                are a must.
              </ParagraphText>
            </Div>
            <Div>
              <Span> </Span>
              <DutyList>
                <ListItem>
                  <ParagraphText>
                    You know the weirdest parts of JavaScript and love working
                    with it anyway.
                  </ParagraphText>
                </ListItem>
                <ListItem>
                  <ParagraphText>
                    You have extensive experience working with the performance
                    profiling and debugging tools in your web browser of choice.
                  </ParagraphText>
                </ListItem>
                <ListItem>
                  <ParagraphText>
                    You love keeping up to date with the evolving capabilities
                    of JavaScript, but know when to temper that excitement with
                    caution.
                  </ParagraphText>
                </ListItem>
                <ListItem>
                  <ParagraphText>
                    You’ve worked with React (or at least understand why it’s
                    important). Familiarity with Redux will also come in handy.
                  </ParagraphText>
                </ListItem>
                <ListItem>
                  <ParagraphText>
                    You’ve been working with front end frameworks and libraries
                    professionally for 3+ years (and having contributed to some
                    is a big plus).
                  </ParagraphText>
                </ListItem>
                <ListItem>
                  <ParagraphText>
                    Diving into a new system to understand mysterious behavior
                    excites you, and the phrase “things are slow, but only
                    sometimes” sounds like the start of a great adventure.
                  </ParagraphText>
                </ListItem>
                <ListItem>
                  <ParagraphText>
                    You write understandable, testable code with an eye towards
                    maintainability and scalability.
                  </ParagraphText>
                </ListItem>
                <ListItem>
                  <ParagraphText>
                    You are a strong communicator and are equally comfortable
                    talking with your immediate team, other frontend engineers,
                    or even backend infrastructure engineers.
                  </ParagraphText>
                </ListItem>
                <ListItem>
                  <ParagraphText>
                    You are ready to learn, but just as excited to teach.
                  </ParagraphText>
                </ListItem>
                <ListItem>
                  <ParagraphText>
                    You understand that the perfect solution is informed by the
                    people who will be building on your work, rather than being
                    purely dependent on technical merits. Empathy is a feature
                  </ParagraphText>
                </ListItem>
                <ListItem>
                  <ParagraphText>
                    You have a bachelor's degree in Computer Science,
                    Engineering or a related field, or equivalent training,
                    fellowship, or work experience.{" "}
                  </ParagraphText>
                </ListItem>
              </DutyList>
            </Div>
            <Div>
              <Example />
            </Div>
          </Container>
        </FullScreen>
      </Section>
    </>
  );
};

export default SoftwarePage;
