import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import {
  StyleContainer,
  TextBox,
  CirclePosition,
  EdgeButton,
  Column,
  FullRow
} from "src/components/TechnologyEdge/Style";
import {
  TextHeader,
  ParagraphText,
  TransparentButton,
} from "src/components/Designs";




const TechnologyEdge: React.FC = () => {
  return (
    <Container fluid={true}>
      <FullRow addpad={'80px'}>
        <StyleContainer className="wow fadeInLeft animated" lg={5} md={5} xl={5} sm={5} xs={5}></StyleContainer>
        <Column lg={1} xl={2} className="distance "></Column>
        <TextBox className="wow fadeInRight animated" lg={5} md={6} xl={4} sm={7} xs={6} addjustify="end">
          <CirclePosition>
            <TextHeader>Technology edge</TextHeader>
          </CirclePosition>
          <ParagraphText>
              We always try to define technology edge projects which can solve
              new problems. Our talented team can find solutions for every kind
              of problems. We believe in our team.
          </ParagraphText>
          <EdgeButton>
            <TransparentButton>Read More</TransparentButton>
          </EdgeButton>
        </TextBox>
        <Column lg={1} xl={1}></Column>
      </FullRow>
    </Container>
  );
};
export default TechnologyEdge;
