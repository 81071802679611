import React from "react";
import {
  FullScreen,
  Section,
  Span,
  Div,
  DutyList,
  ListItem,
  JobCircle
} from "src/pages/career/style";
import { ParagraphText, TextHeader, ButtonAbout } from "src/components/Designs";
import Example from "src/components/modal/index";
import { Container } from "react-bootstrap";

const DesignerPage: React.FC = () => {
  return (
    <>
      <Section>
        <FullScreen>
          <Container>
            <JobCircle>
            <Div>
              <TextHeader>UI/UX Designer</TextHeader>
            </Div>
            </JobCircle>
           
            <Div>
              <ParagraphText>
                OldRobo has a positive, diverse, and supportive culture. we look
                for people who are curious, inventive, and work
              </ParagraphText>
              <ParagraphText>
                to be a little better every single day. In our work together we
                aim to be smart, humble, hardworking and, above all,
              </ParagraphText>
              <ParagraphText>
                collaborative. If this sounds like a good fit for you, why not
                say hello?
              </ParagraphText>
            </Div>

            <Div>
              <Span>Director of Product Design, Expansion</Span>
              <ParagraphText>
                OldRobo is hiring a Director of Product Design to lead the
                Expansion Design team.
              </ParagraphText>
              <ParagraphText>
                Our Product Design team serves a vital role at the company, from
                creating the vision for new features, to the craft and finish
              </ParagraphText>
              <ParagraphText>
                of every detail in the product. We appreciate designers who
                think deeply, speak clearly, and embrace collaboration and
                feedback. We expect candidates for this role to have an
                understanding of how to frame and build experiments from a
                design
              </ParagraphText>
              <ParagraphText>
                point of view. This is a fast paced team that likes to ship, and
                also owns a good blend of experiments and bigger features.
              </ParagraphText>
            </Div>
            <Div>
              <Span>Director of Product Design, Expansion</Span>
              <DutyList>
                <ListItem>
                  <ParagraphText>
                    You'll report to the Head of Product Design, and work
                    closely with Product, Design and Engineering leadership
                    peers across company.
                  </ParagraphText>
                </ListItem>
                <ListItem>
                  <ParagraphText>
                    You'll drive the design vision and strategy for Expansion
                    Design, which includes Activation, Monetization, Adoption
                    and International.{" "}
                  </ParagraphText>
                </ListItem>
                <ListItem>
                  <ParagraphText>
                    You'll build, lead and mentor a team of Designers and
                    Managers in service of the Expansion team’s mission and
                    goals.
                  </ParagraphText>
                </ListItem>
                <ListItem>
                  <ParagraphText>
                    You’ll lead design critique, design reviews, quarterly
                    planning, quarterly business reviews, and design sprint
                    planning.{" "}
                  </ParagraphText>
                </ListItem>
                <ListItem>
                  <ParagraphText>
                    You’ll also help ensure that teams and projects are properly
                    staffed, and that work is balanced for the team’s ability,
                    desire, and time.
                  </ParagraphText>
                </ListItem>
                <ListItem>
                  <ParagraphText>
                    Recruit, interview, and hire Designers and Design Managers,
                    and actively improve our inclusive design hiring process.
                  </ParagraphText>
                </ListItem>
                <ListItem>
                  <ParagraphText>
                    You’ll partner with product and engineering leadership to
                    represent the design team, lead design initiatives, and
                    provide feedback in planning and product channels.
                  </ParagraphText>
                </ListItem>
              </DutyList>
            </Div>
            <Div>
              <Span>What you should have</Span>
              <DutyList>
                <ListItem>
                  <ParagraphText>
                    3+ years of Extensive and relevant work experience leading
                    and growing a high-performing Product Design team.
                  </ParagraphText>
                </ListItem>
                <ListItem>
                  <ParagraphText>
                    An outstanding portfolio, showcasing a foundation in
                    typography, interaction and visual design,and ideation.{" "}
                  </ParagraphText>
                </ListItem>
                <ListItem>
                  <ParagraphText>
                    Extensive experience with prototyping as a means to explore
                    and convey design solutions.
                  </ParagraphText>
                </ListItem>
                <ListItem>
                  <ParagraphText>
                    A nuanced understanding of diverse and inclusive hiring
                    practices.{" "}
                  </ParagraphText>
                </ListItem>
                <ListItem>
                  <ParagraphText>
                    Comfort with ambiguity and grace under pressure.
                  </ParagraphText>
                </ListItem>
                <ListItem>
                  <ParagraphText>
                    Excellent written and verbal communication skills.
                  </ParagraphText>
                </ListItem>
              </DutyList>
            </Div>
            <Div>
              <Example />
            </Div>
          </Container>
        </FullScreen>
      </Section>
    </>
  );
};

export default DesignerPage;
