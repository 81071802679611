import React from "react";
import country from 'src/country/country';

const CountryData:React.FC = () => {
    return ( 
       <>
       <option value="">Select</option>
        {country.map(x => <option value={x.name}>{x.name}</option>)}
       </>
    );
  };
  
  export default CountryData;
