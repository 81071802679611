import styled from 'styled-components';
import { HeaderCircle, } from "src/components/Designs";
import { Row, Col,Container } from "react-bootstrap";

export const CirclePosition = styled(HeaderCircle)`
  ::before {
    bottom: 0px;
    right: 119px;
    @media(max-width:992px){
      right: 94px;
    }
    @media(max-width:992px){
      right: 80px;
    }
    @media(max-width:576px){
      bottom: -2px;
      right:69px;
    }
    @media(max-width:418px){
      right: 60px;
    }
  }
`;

export const AchievementCircle=styled(HeaderCircle)`
::before {
    bottom: 0;
    left: -6px;
  }
`;
export const AboutCirclePosition=styled(HeaderCircle)`
::before {
      bottom: 6px;
      left: -7px;
  }
`;

export const StyleGoalContainer = styled(Col)`
  margin: 0px;
  background-image: url(./assets/images/goal.png);
  background-size: contain;
  background-repeat: no-repeat;
  height: 100vh;
  @media (max-width: 1440px) {
    height: 82vh;
  }
  @media (max-width: 1280px) {
    height: 75vh;
  }
  @media (max-width: 1024px) {
    height: 42vh;
  }
  @media (max-width : 1024px ) and (orientation: landscape) {
    height: 74vh;
  }
  @media (max-width : 992px ) and (orientation: landscape) {
    height: 92vh;
  }
`;

export const AchievementImg = styled.img`
width: 60%;
`;

export const ImgCol=styled(Col)`
display: flex;
justify-content: flex-end;
background-image: url(./assets/images/achievement.png);
background-size: contain;
background-repeat: no-repeat;
height: 60vh;
background-position: center;
@media(max-width:768px){
  height: 35vh;
  background-position: left;
}
@media(min-width:992px) and (max-width:1024px){
  height: 32vh;
}
`;

export const DivBack=styled.div`
background-image: url(./assets/images/aboutback.png);
background-size: contain;
background-repeat: no-repeat;
height: 100vh;

@media(max-width:1024px){
  height:45vh;
}

@media(max-width:576px){
  height: 70vh;
}
`;


 //our team section
 
export const Column_Text = styled(Col)`
  text-align: center;
  margin-top: 50px;
`;

export const Row_member = styled(Row)`
  justify-content: center;
  padding-top : 30px ;
`;

export const Column_circle = styled(Col).attrs((props:any) => {})`
  display: flex ;
  justify-content : ${props => (props.RightCircle ? "flex-end" : "unset")};
  align-items: ${props => (props.RightCircle ? "flex-end" : "unset")};

  padding : 0px ;
`

export const TeamContainer=styled.div`
width: 100%;
padding-right: 15px;
padding-left: 15px;
margin-right: auto;
margin-left: auto;
@media(max-width:576px ){
  padding-right: 35px;
  padding-left: 35px;
  padding-top:50px;
  padding-bottom:50px;
}

`;

export const BackTeam=styled.div`

background-image: url(./assets/images/backteam.png);
background-size: contain;
background-repeat: no-repeat;
width: 100%;


`;