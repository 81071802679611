import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import {
  StyleGoalContainer,
  ImgCol,
  CirclePosition,
  AchievementCircle,
  Column_Text,
  Column_circle,
  Row_member,
  AboutCirclePosition,
  TeamContainer,
  DivBack,
  BackTeam
} from "src/pages/about-us/style";
import { MainDiv, Section } from "src/pages/career/style";
import { TextHeader, ParagraphText } from "src/components/Designs";
import { TextBox, Column, FullRow } from "src/components/TechnologyEdge/Style";
import Team from "src/components/Team";
// import member from "public/assets/images/member.png";
import IconMohammad from "public/assets/images/mohammad.png";
import IconErmia from "public/assets/images/ermia.png";
import IconHossein from "public/assets/images/hossein.png";
import IconMehrdad from "public/assets/images/mehrdad.png";
import IconHanie from "public/assets/images/hanieh.png";
import IconDena from "public/assets/images/dena.png";
import IconZahra from "public/assets/images/zahra.png";
import Footer from "src/components/Footer";

const AboutUs: React.FC<any> = props => {
  return (
    <Section>
      <DivBack>
        <Container className="wow slideInDown">
          <MainDiv>
            <AboutCirclePosition>
              <TextHeader>About us</TextHeader>
            </AboutCirclePosition>
            <ParagraphText>
              TNEED company was born in 2018 and it has grown rapidly and now it
              has 15 employees as developers, designers, marketing managers and
              the supporters.
            </ParagraphText>
          </MainDiv>
        </Container>

        <FullRow addpad={"270px"} addminpadd={"80px"}>
          <StyleGoalContainer
            className="wow fadeInLeft animated"
            lg={5}
            md={5}
            xl={5}
            sm={5}
            xs={5}
          ></StyleGoalContainer>
          <Column lg={1} xl={2} className="distance "></Column>
          <TextBox
            className="wow fadeInRight animated"
            lg={5}
            md={6}
            xl={4}
            sm={7}
            xs={6}
            addjustify="end"
          >
            <CirclePosition>
              <TextHeader>Our goals</TextHeader>
            </CirclePosition>
            <ParagraphText>
              TNEED is built on technology and its mission is to build the new
              technologies to solve the problems. We rely on our highly talented
              team which consult the other companies as well to solve their
              issues. We believe we can change the world to make it a better
              place to live.
            </ParagraphText>
          </TextBox>
          <Column lg={1} xl={1}></Column>
        </FullRow>
      </DivBack>

      <FullRow addpad={"270px"} addminpadd={"80px"}>
        <Column lg={1} xl={1} md={1} xs={1}></Column>
        <TextBox
          className="wow fadeInRight animated"
          lg={5}
          md={5}
          xl={4}
          sm={7}
          xs={6}
          addjustify="left"
        >
          <AchievementCircle>
            <TextHeader>Achievement</TextHeader>
          </AchievementCircle>
          <ParagraphText>
            We also have some awards in different fields of technologies like
            Web technologies and Graphic Design in World Skills Competition, and
            candidate of best of the cloud technology in IWMF festival.
          </ParagraphText>
        </TextBox>
        <ImgCol
          className="wow fadeInLeft animated"
          lg={6}
          md={6}
          xl={7}
          sm={5}
          xs={4}
        ></ImgCol>
      </FullRow>

      <FullRow addpad={"270px"} addminpadd={"80px"}>
        <BackTeam>
          <TeamContainer>
            <MainDiv>
              <AboutCirclePosition>
                <TextHeader>Our team</TextHeader>
              </AboutCirclePosition>
            </MainDiv>
            <Row>
              <Column_circle
                xs={12}
                md={1}
                xl={1}
                lg={1}
                className="wow bounceInLeft"
              ></Column_circle>
              <Col md={1} xl={2} lg={1}></Col>
              <Column_Text xs={12} md={8} xl={6} lg={8}>
                <Row className="wow zoomIn">
                  <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                    <Team Name="Leo Rezaei" Job="CEO" Icon={IconMohammad} />
                  </Col>
                  <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                    <Team Name="Ermia Qasemi" Job="DevOps Engineer" Icon={IconErmia} />
                  </Col>
                  <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                    <Team Name="Hossein Nedaee" Job="Developer" Icon={IconHossein} />
                  </Col>
                  <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                    <Team
                      Name="Mehrdad Bonyani"
                      Job="Developer"
                      Icon={IconMehrdad}
                    />
                  </Col>
                </Row>
                <Row_member className="wow zoomIn">
                  
                  <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                    <Team Name="Dena Afrasiyabi" Job="Designer" Icon={IconDena} />
                  </Col>
                  <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                    <Team Name="Zahra Mohebi" Job="Developer" Icon={IconZahra} />
                  </Col>
                </Row_member>
              </Column_Text>
              <Col md={1} xl={2} lg={1}></Col>
              <Column_circle
                RightCircle
                xs={12}
                md={1}
                xl={1}
                lg={1}
                className="wow bounceInRight"
              ></Column_circle>
            </Row>
          </TeamContainer>
        </BackTeam>
      </FullRow>

     
    </Section>
  );
};
export default AboutUs;
