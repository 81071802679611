import styled from "styled-components";

export const Wrapper = styled.div``;

export const ParentButton = styled.div`
    display: flex ;
    padding-top : 56px ;
    @media (max-width : 1366px){
        padding-top : 35px ;
    }
`;
export const TransparentButton = styled.button`
    color : white ;
    border: 2px solid #00AAC0;
    background-color: transparent;
    font-size : 15px ;
    padding: 7px 24px 10px 31px;
    @media ( max-width : 680px ){
      padding: 7px 18px 10px 21px;
   
    }
   @media(max-width:576px){
    padding: 7px 13px 7px 13px;
    font-size: 12px;
   }
`;
export const ButtonAbout = styled.button`
    color : white ;
    background-color: #00AAC0;
    border: none;
    margin-left: 36px;
    font-size : 15px ;
    padding: 7px 40px 10px 42px;
    @media ( max-width : 680px ){
      padding: 7px 33px 10px 33px;
    }
    @media ( max-width : 576px ){
      padding: 7px 21px 7px 21px;
      font-size: 12px;
    }
`;
export const HeaderCircle = styled.div`
  position: relative;
  ::before {
    content: "";
    position: absolute;
    background-color: #f05a22;
    border-radius: 60px;
    --max-size: 47;
    --min-size: 31;
    width: var(--responsive);
    height: var(--responsive);
  }
`;

export const TextHeader = styled.h2.attrs((props:any)=>{})`
  color: #ffffff;
  font-weight: 600;
  --max-size: 35;
  --min-size: 20;
  font-size: var(--responsive);
  position: relative;
  margin-bottom:${props =>(props.size)};
  
  @media(max-width:576px){
    margin:calc((${props =>(props.size)}) - (20px));
  }
`;

export const Text = styled.p`
  color: #ffffff;
  font-size: 22px;
  `;

export const ParagraphText = styled.div`
  font-weight: 300;
  --max-size: 18;
  --min-size: 14;
  font-size: var(--responsive);
  color: #ffffff;

`;

