import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Form, Col } from "react-bootstrap";
import {
  Section,
  Div,
  WorldImage,
  FormInputs,
  FormGroup,
  FormLabel,
  FormTextArea,
  Column,
  BasicFooter,
  DivButton,
  TotalDiv,
  CirclePosition,
  ImgCol
} from "src/pages/contact-us/style";
import { TextHeader, ButtonAbout } from "src/components/Designs";
import {
  SocialRef,
  TextPosition,
  List,
  SocialIcon,
  Instagram
} from "src/components/Footer/Style";

const ContactUs: React.FC <any> = props => {
  return (
    <Section>
      <Row>
        <Col lg={2} xl={2}></Col>
        <Column sm={12} md={12} lg={5} xl={5} className="wow slideInLeft">
          <CirclePosition>
            <TextHeader size="40px">Contact Us</TextHeader>
          </CirclePosition>
          <Form>
            <FormGroup>
              <FormLabel>Name</FormLabel>
              <FormInputs type="email" placeholder="" />
            </FormGroup>
            <FormGroup>
              <FormLabel>Email</FormLabel>
              <FormInputs type="email" placeholder="" />
            </FormGroup>
            <FormGroup>
              <FormLabel>Message</FormLabel>
              <FormTextArea />
            </FormGroup>
            <DivButton>
              <ButtonAbout className="ml-0">Send</ButtonAbout>
            </DivButton>
          </Form>
        </Column>
        <ImgCol sm={12} md={12} lg={5} xl={5} className="wow slideInRight">
          <Div>
            <WorldImage src="./assets/images/world.png"></WorldImage>
          </Div>
        </ImgCol>
      </Row>
      <BasicFooter className="wow slideInDown">
        <TotalDiv>
          {/* <TextPosition>
            <List>Address:</List>
            <List>Canada-Vancouver</List>
          </TextPosition> */}
          <TextPosition>
            <List>Email:</List>
            <List>hello@tneed.com</List>
          </TextPosition>
          <TextPosition>
            <List>Phone:</List>
            <List>+1 (418) 264-8977</List>
          </TextPosition>
          <List>
            <SocialRef href="">
              <SocialIcon className="fab fa-linkedin-in"></SocialIcon>
            </SocialRef>
            <SocialRef href="">
              <SocialIcon className="instagram-border">
                <Instagram className="fab fa-instagram"></Instagram>
              </SocialIcon>
            </SocialRef>
            <SocialRef href="">
              <SocialIcon className="fab fa-facebook-f"></SocialIcon>
            </SocialRef>
            <SocialRef href="">
              <SocialIcon className="fab fa-twitter"></SocialIcon>
            </SocialRef>
          </List>
        </TotalDiv>
      </BasicFooter>
    </Section>
  );
};
export default ContactUs;
