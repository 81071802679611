import styled from 'styled-components';
import {Col} from "react-bootstrap";
import { HeaderCircle, } from "src/components/Designs";

export const Section=styled.section`
height: 100vh;
display: flex;
flex-direction: column;
justify-content: center;
padding-top: 100px;
`;

export const Column=styled(Col)`
    justify-content:center;
    display: flex;
    flex-direction:column;
    position: relative;
    @media(max-width:768px){
        order:1;
        align-items: center;
        ::after{
            content: "";
            align-items: center;
            background-image: url(./assets/images/world.png);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            height: 40vh;
            opacity: 0.3;
            top: 45px;
            left: 180px;
            right: 0px;
            position: absolute;
            z-index: -1;
            @media(max-width:414px){
                top: 74px;
                left: 108px;            
            }
        }
    }
    
`;


export const  Div=styled.div`
display: flex;
justify-content: flex-end;
@media(max-width:768px){
    display: flex;
    justify-content: flex-end;
}
`;
export const WorldImage=styled.img`
width:100%
@media(max-width:768px){
    width:75%;
}
`;

 
export const FormInputs=styled.input`
border: 2px solid #00AAC0;
background: transparent;
width: 340px;
margin-bottom: 15px;
padding:5px 10px;
@media(max-width:576px){
    width:255px;
}
@media(min-width:1920px){
    width:480px;
}
`;

export const FormTextArea=styled.textarea`
border: 2px solid #00AAC0;
background: transparent;
height: 90px;
width: 340px;
margin-bottom: 25px;
@media(max-width:576px){
    width:255px;
}
@media(min-width:1920px){
    width:480px;
}
`;

export const FormGroup=styled.div`
display: flex;
flex-direction: column;

`;

export const FormLabel=styled.label`
color:white;
`
export const BasicFooter=styled.footer`

padding: 70px 150px 0px 150px;
@media(max-width:768px){
    padding: 70px 0px 0px 0px;
}
@media(max-width:576px){
    flex-direction: column;
}
`;

export const DivButton= styled.div`
@media(max-width:768px){
    display:flex;
    justify-content:center;
}
`;


export const TotalDiv=styled.div`
display: flex;
flex-direction: row;
justify-content: space-evenly;
align-items: center;
@media(max-width:576px){
    width: 125px;
    margin-left: auto;
    margin-right: auto;
   flex-direction: column;
    align-items: flex-start;
}
`;

export const CirclePosition = styled(HeaderCircle)`
  ::before {
    bottom: 41px;
    left: -12px;
    @media(max-width:576px){
        bottom: 19px;
        left: 10px;
    }
`;

export const ImgCol=styled(Col)`
align-items: center;
display: flex;
@media(max-width:768px){
    display:none
}

`