import styled from 'styled-components';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { HeaderCircle, ParentButton } from "src/components/Designs";




export const StyleContainer = styled(Col)`
  margin: 0px;
  background-image: url(./assets/images/edge.png);
  background-size: contain;
  background-repeat: no-repeat;
  height: 100vh;
  @media (max-width: 1440px) {
    height: 82vh;
  }
  @media (max-width: 1280px) {
    height: 75vh;
  }
  @media (max-width: 1024px) {
    height: 42vh;
  }
  @media (max-width : 1024px ) and (orientation: landscape) {
    height: 74vh;
  }
  @media (max-width : 992px ) and (orientation: landscape) {
    height: 92vh;
  }
`;

export const TextBox = styled(Col).attrs((props:any) => {})`
  padding-top: 130px;
  text-align:  ${props => (props.addjustify)};
  @media(max-width:818px){
   padding-top:65px;

  }
`;

export const CirclePosition = styled(HeaderCircle)`
  ::before {
    bottom: 10px;
    left: 236px;
    @media (max-width: 1440px) {
      left: 140px;
    }
    @media (max-width: 1280px) {
      left: 114px;
    }
    @media (max-width: 1024px) {
      left:157px;
      bottom: 9px;
    }
    @media (max-width:768px) {
      left:150px;
    }
    @media (max-width:736px) and (orientation: landscape) {
      left:183px;
    }
    @media (max-width:667px) and (orientation: landscape) {
      left:157px;
    }
    @media (max-width:568px) {
      left:70px;
      bottom: 5px;
    }
    @media (max-width:414px) {
      left:25px;
      bottom: 7px;
    }
    @media (max-width:375px) {
      left:12px;
    }
    @media (max-width:320px) {
      left:43px;
      bottom: 24px;

    }
  }
`;

export const EdgeButton = styled(ParentButton)`
  justify-content: flex-end;
  padding-top: 0px;
  padding-top: 56px;
`;
export const Column = styled(Col)`
  
`;
export const FullRow = styled(Row).attrs((props:any) => {})`
padding-top: ${props => (props.addpad)};
@media (max-width:992px) {
  .distance{
    display: none;
  }
}

@media(max-width:576px){
  padding-top: ${props => (props.addminpadd)};
}

`;
