import styled from "styled-components";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

export const Header=styled(Modal.Header)`
border-bottom:none;
`;
export const  Modals=styled(Modal)`
margin:0 auto;
width:600px;

`;
export const Spans=styled.span`
color:red
font-size: 45px;
`;


export const ApplySubmit = styled.button`
  margin-top: 30px;
  display: flex;
  .spinner-grow{
    width:1rem;
    height:1rem;
  }
`;

export const FormControl = styled(Form.Control)`
  border-radius: 10px;
  border: 1px solid #757575;
  padding: 3px 0 3px 10px;
  width:300px;
  outline: 0;
 @media(max-width:992px){
   width:200px;
 }
 @media(max-width:576px){
  width:145px;
}
@media(max-width:375px){
  width:100px;
  
}
`;

export const FormInput = styled.input`
  border-radius: 10px;
  border: 1px solid #757575;
  padding: 3px 0 3px 10px;
  width: 300px;
  outline: 0;
  @media(max-width:992px){
    width:200px;
  }
  @media(max-width:576px){
    width:145px;
  }
  @media(max-width:375px){
    width:100px;
  }
`;

export const FormRow = styled(Form.Row)`
  flex-wrap: nowrap;
  font-size: 12px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
`;

export const FormGroup = styled(Form.Group)`
  flex-grow: 0;
  margin-right: 30px;
  @media (max-width: 480px) {
    margin-right: 15px;
  }
`;

export const File = styled.i`
  position: absolute;
  font-size: 15px;
  right: 10px;
  top: 4px;
  color: #757575;
`;

export const FileDiv = styled.label`
  position: relative;
  border-radius: 10px;
  border: 1px solid #757575;
  font-size: 12px;
  padding: 3px 0 20px 10px;
  width: 300px;
  outline: 0;
  margin-bottom: 0;
  @media(max-width:375px){
    font-size:10px;
  }
  @media(max-width:992px){
    width:200px;
  }
  @media(max-width:576px){
   width:145px;
 }
 @media(max-width:375px){
   width:100px;
   
 }
`;

export const Div = styled.div``;

export const NewForm = styled(Form)`
  
`;

export const Button=styled.button`
&:focus{
  outline:none;
}

`;
