import React from "react";
import IntroductionTneed from "src/components/Introduction";
import TechnologyEdge from "src/components/TechnologyEdge";
import PortfolioTneed from "src/components/Portfolio"
// import PortfolioTneed from "src/components/Portfolio";
import Footer from "src/components/Footer";

const HomePage: React.FC = () => {
  return(
    <>
    <IntroductionTneed /> 
    <TechnologyEdge />
    <PortfolioTneed />
    
  </>
  )
};

export default HomePage;
