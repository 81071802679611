import styled from "styled-components";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Background from 'public/assets/images/footer.png';


export const FooterBackground = styled(Col)`
  margin: 0px;
  background-image: url(${Background});
  background-size: contain;
  background-repeat: no-repeat;
  background-position : bottom left ;
  height: 65vh;
  
  @media (max-width : 1440px ){
    height: 53vh;
  }
  @media (max-width : 1024px ){
    height: 25vh;
  }
  @media (max-width : 1024px ) and (orientation: landscape) {
    height: 40vh;
  }
  @media (max-width : 576px ){
    display : none ;
  }
  }
  
`;

export const CompanyInformation = styled(Col)`
  display: flex;
  align-items: flex-end;
  position: relative;
  justify-content: flex-end ;
  @media (max-width : 768px ){
    justify-content: center ;
  }
  @media (max-width: 576px) {
    background-image: url(./assets/images/footer.png);
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    align-items: end;
    justify-content: flex-end ;
  }
  
`;

export const SocialAlign = styled.div`
  height : 75% ;
  display: flex;
    flex-direction: row;
`

export const List = styled.p`
  color: #ffffff;
  font-weight: 100;
  --max-size: 14;
  --min-size: 5;
  font-size: var(--responsive);
  display: flex;

  @media screen and (orientation: landscape) {
    margin-bottom: 10px;
  }

  @media (max-width: 576px) {
    font-size: 10px;
    font-weight: 300;
    margin-bottom: 10px;
  }

  .fa-twitter {
    border: 1px solid #007bff;
  }
  .fa-instagram {
    color: transparent;
    background: -webkit-radial-gradient(
      30% 107%,
      circle,
      #fdf497 0%,
      #fdf497 5%,
      #fd5949 45%,
      #d6249f 60%,
      #285aeb 90%
    );
    background: -o-radial-gradient(
      30% 107%,
      circle,
      #fdf497 0%,
      #fdf497 5%,
      #fd5949 45%,
      #d6249f 60%,
      #285aeb 90%
    );
    background: radial-gradient(
      circle at 30% 107%,
      #fdf497 0%,
      #fdf497 5%,
      #fd5949 45%,
      #d6249f 60%,
      #285aeb 90%
    );
    background: -webkit-radial-gradient(
      circle at 30% 107%,
      #fdf497 0%,
      #fdf497 5%,
      #fd5949 45%,
      #d6249f 60%,
      #285aeb 90%
    );
    background-clip: text;
    -webkit-background-clip: text;
  }
  .fa-linkedin-in {
    border: 1px solid #007ab9;
    color: #007ab9;
  }
  .fa-facebook-f {
    border: 1px solid #3c5a99;
    color: #3c5a99;
  }
  .instagram-border {
    border: 1px solid #fbe18a;
  }
`;

export const TneedLogo = styled.div`
  padding-left: 35px;
  max-width: 150px;


  @media (max-width: 736px) {
    padding-left: 11px;
    width: 70px;
  }
  @media (max-width: 768px) {
    padding-left: 16px;
  }
`;

export const TotalList = styled.div`
  line-height: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  & > ${List} {
    border-top: 1px solid #f05a22;
    max-width: 85%;
    padding-top: 10px;
  }
`;

export const TextPosition = styled.div`
  display: flex;
  flex-direction: row;
  p:nth-child(2) {
    padding-left: 8px;
  }
`;
export const SocialRef = styled.a`
  padding-right: 10px;
`;

export const SocialIcon = styled.span`
  background-color: white;
  border-radius: 50%;
  width: 20px;
  display: flex;
  justify-content: center;
  height: 20px;
  align-items: center;
  font-size: 12px;
  text-align: center;
`;

export const Instagram = styled.i``;

export const FullRow = styled(Row)`
  position: relative;
  @media screen and (max-width: 375px) {
    padding-top: 100px;
    padding-right: 10%;
  }
  .distance{
    @media (max-width:414px) {
      padding-right: 10%;
      padding-top: 10%;
    }
    @media (max-width:375px) {
      padding-right:15px;
    }
  }
`;
export const Column = styled(Col)`
  @media (max-width: 768px) {
    display: none;
  }
`;

