import styled from "styled-components";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { TransparentButton, HeaderCircle } from "src/components/Designs";

export const ContainerFluid = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const PortfolioImage = styled.div`
  background-image: url(./assets/images/portfolio.png);
  background-size: contain;
  width: 100%;
  height: 95vh;
  background-position: right;
  background-repeat: no-repeat;
  display: flex;
  @media (max-width: 1024px) {
    height: 65vh;
  }
  @media (max-width: 768px) {
    height: 85vh;
  }
  @media (max-width: 576px) {
    height: 100%;
    padding-top: 100px;
  }
  @media (max-width: 568px) and ( min-width : 411px ) {
    padding-bottom: 100px;
  }

  @media (max-width: 1024px) and (orientation: landscape) {
    height: 100%;
    padding-top: 100px;
  }
`;
export const StyleCol = styled(Col)`
  text-align: center;
`;
export const RowPort = styled.div`
  display: flex;
  max-width: 628px;
  margin: 0px auto;
  align-items: flex-end;
`;

export const ButtonDesign = styled(TransparentButton)`
  margin: auto;
`;
export const CirclePort = styled(HeaderCircle)`
  ::before {
    top: -9px;
    left: 28%;
    @media (max-width: 1440px) {
      top: -8px;
      left: 32%;
    }
    @media (max-width: 1280px) {
      left: 33%;
    }
    @media (max-width: 1024px) {
      left: 35%;
    }
    @media (max-width: 823px) {
      left: 36%;
    }
    @media (max-width: 768px) {
      top: -4px;
      left: 37%;
    }
    @media (max-width: 414px) {
      top: -6px;
      left: 33%;
    }
  }
`;

export const RowProducts = styled(Row)`
  justify-content: center;
  margin-top: 80px;
  @media (max-width: 576px) {
    margin-top: 10px;
  }
`;
export const Products = styled(Col)`
  flex-direction: row;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`;
export const ProductsTneed = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  --max-size: 190;
  --min-size: 120;
  border-radius: 15px;
  width: calc(var(--responsive) + 200px );
  height: var(--responsive);
  background: #EDE3F0 0% 0% no-repeat padding-box;
  box-shadow: 12px 12px 12px #00000081;
  opacity: 0.89;
  margin: 28px 0px ;
  padding: 17px;
  @media (max-width: 1440px) {
    --max-size: 160;
  }
  @media (max-width: 1024px) {
    --max-size: 205;
    --min-size: 150;
  }
`;

export const DescriptionPorto = styled.p`
    color: #260246;
    font-size: 16px;
    text-align: center;
    width: 277px;
    padding-top: 15px;
    margin-bottom: 0rem;
    @media (max-width : 1440px ){
      font-size: 14px;
      padding-top: 11px;
    }
`

export const PortoLogo = styled.img`
  width : 100% ;
  height : auto ;
`

export const OldroboProduct = styled.a`
  width: 40%;
  @media (max-width : 1440px ){
    width: 35%;
  }
`
export const SourceProduct = styled.a`
width: 34%;
@media (max-width : 1440px ){
    width: 29%;
  }
`