import React, { Fragment } from "react";

import Router from "src/routes";
import { Wrapper } from "src/components/Designs";
import GlobalStyle from "src/Theme/Global"




const App: React.FC = () => (
  <Fragment>
    <GlobalStyle />
    <Wrapper id="navbarClose">
      <Router />
    </Wrapper>
  
   
   
    
  </Fragment>
);

export default App;
