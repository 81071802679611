import React from "react";
import {
  Figure_Team,
  Image_Team,
  Figcaption_Team,
  Name_Team
} from "src/components/Team/style";


const Team: React.FC<any> = (props) => {
  return (
    <Figure_Team>
      <Image_Team src={props.Icon} />
      <Figcaption_Team>
        <Name_Team>{props.Name}</Name_Team>
        <Name_Team>{props.Job}</Name_Team>
      </Figcaption_Team>
    </Figure_Team>
  );
};

export default Team ;