import React from "react";
import {
  FullScreen,
  Section,
  Span,
  Div,
  DutyList,
  ListItem,
  JobCircle
} from "src/pages/career/style";
import { ParagraphText, TextHeader, ButtonAbout } from "src/components/Designs";
import Example from "src/components/modal/index";
import { Container } from "react-bootstrap";

const MarketingPage: React.FC = () => {
  return (
    <>
      <Section>
        <FullScreen>
          <Container>
            <JobCircle>
              <Div>
                <TextHeader>Marketing Manager</TextHeader>
              </Div>
            </JobCircle>
            
            <Div>
              <ParagraphText>
                OldRobo has a positive, diverse, and supportive culture. we look
                for people who are curious, inventive, and work
              </ParagraphText>
              <ParagraphText>
                to be a little better every single day. In our work together we
                aim to be smart, humble, hardworking and, above all,
              </ParagraphText>
              <ParagraphText>
                collaborative. If this sounds like a good fit for you, why not
                say hello?
              </ParagraphText>
            </Div>

            <Div>
              <Span>International Marketing Manager</Span>
              <ParagraphText>
                The Marketing Partnerships team is establishing an
                “International Growth” team to build and help execute playbook
                for short and long-term international growth, including market
                prioritization and localization strategy. The team will analyze
                and assess US & non-US market opportunities, make
                recommendations for tests and market programs, and execute those
                tests.
              </ParagraphText>
              <ParagraphText>
                We are looking for an International Growth Manager who will help
                influence and execute on our international growth.
              </ParagraphText>
            </Div>
            <Div>
              <Span>What you will be doing</Span>
              <ParagraphText>
                Build and foster relationships with stakeholders to identify,
                and create, track, and gather insights around initiatives for
                international growth. Help build a roadmap of strategic
                initiatives and experiments using global market data, customer
                insights, and international growth best practices in
                collaboration with cross-functional teams. Develop strategy and
                manage execution of marketing and other localization initiatives
                to meet customer needs and drive growth in key non-US markets.
                Consult with teams leading projects across disciplines to help
                them
              </ParagraphText>
              <ParagraphText>
                work in the international layer, acting as the strategic
                knowledge center for international growth. Define KPIs and help
                maintain a reporting system to measure progress towards
                international growth goals
              </ParagraphText>
            </Div>
            <Div>
              <Span>We'd love to hear from you if: </Span>
              <DutyList>
                <ListItem>
                  <ParagraphText>
                    3+ years international business strategy or international
                    marketing experience.
                  </ParagraphText>
                </ListItem>
                <ListItem>
                  <ParagraphText>
                    Experience working with international teams and managing
                    projects that drive international growth.{" "}
                  </ParagraphText>
                </ListItem>
                <ListItem>
                  <ParagraphText>
                    Experience managing small teams.
                  </ParagraphText>
                </ListItem>
                <ListItem>
                  <ParagraphText>
                    Extensive experience developing strategies with
                    cross-functional teams across a wide variety of disciplines.
                  </ParagraphText>
                </ListItem>
                <ListItem>
                  <ParagraphText>
                    Proven self starter with the ability to work under limited
                    supervision.
                  </ParagraphText>
                </ListItem>
                <ListItem>
                  <ParagraphText>
                    Excellent written and verbal communication skills.
                  </ParagraphText>
                </ListItem>
                <ListItem>
                  <ParagraphText>
                    Ability to manage multiple complex projects concurrently.
                  </ParagraphText>
                </ListItem>
                <ListItem>
                  <ParagraphText>
                    Outstanding teamwork and influence management skills.
                  </ParagraphText>
                </ListItem>
                <ListItem>
                  <ParagraphText>
                    Ability to work with leaders at all levels of the
                    organization.
                  </ParagraphText>
                </ListItem>
                <ListItem>
                  <ParagraphText>
                    Business acumen, data analysis, and ability to build
                    business cases.
                  </ParagraphText>
                </ListItem>
              </DutyList>
            </Div>
            <Div>
              <Example />
            </Div>
          </Container>
        </FullScreen>
      </Section>
    </>
  );
};

export default MarketingPage;
