import styled from "styled-components";

export const Figure_Team = styled.figure`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  max-width: 183px;
  margin-bottom: 0;
`;
export const Image_Team = styled.img`
  width: 79%;
  @media(max-width:576px){
    width: 60%;

  }
`;

export const Figcaption_Team = styled.figcaption`
  color: white;
  font-size: 11px;
  @media(max-width:576px){
    font-size: 8px;

  }
`;

export const Name_Team = styled.p`
margin-top: 10px;
margin-bottom: 0;
`;