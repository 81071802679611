import React from "react";
import {PortfolioImage, SourceProduct, OldroboProduct, DescriptionPorto, PortoLogo, StyleCol, ButtonDesign, CirclePort, RowPort, ProductsTneed, Products, RowProducts, ContainerFluid} from "src/components/Portfolio/style"
import {ParentButton , TextHeader ,ParagraphText} from "src/components/Designs";
import LogoOldrobo from 'public/assets/images/oldrobo.png';
import LogoSource from 'public/assets/images/source.png';
const  PortfolioTneed : React.FC = () => (
    <>
        <PortfolioImage>
            <ContainerFluid fluid={true} >
                <RowPort  className="wow zoomIn animated" >
                    <StyleCol xs={12} sm={12} md={12} lg={12} xl={12} >
                        <CirclePort>
                            <TextHeader>Portfolio</TextHeader>
                        </CirclePort>
                        <ParagraphText>We have shiny portfolio. most of our works are in the field of web services. You can check them here.</ParagraphText>
                        <ParentButton>
                            <ButtonDesign>Read More</ButtonDesign>
                        </ParentButton>
                    </StyleCol>
                </RowPort>
                <RowProducts>
                    <Products xs={12} sm={12} md={12} lg={11} xl={10} >
                        <ProductsTneed className="wow fadeInLeft animated" >
                            <OldroboProduct href="https://oldrobo.com/" target="blank">
                                <PortoLogo src={LogoOldrobo} />
                            </OldroboProduct>
                            <DescriptionPorto>OldRobo is an amazing service that
                                helps managers to increase Employee’s
                                Performance
                            </DescriptionPorto>
                        </ProductsTneed>
                        <ProductsTneed className="wow fadeInRight animated" >
                            <SourceProduct href="https://sourcegit.com/" target="blank">
                                <PortoLogo src={LogoSource} />
                            </SourceProduct>
                            <DescriptionPorto> Sourcegit is a service for 
                                developers and teams to work together and
                                manage projects
                            </DescriptionPorto>
                        </ProductsTneed>
                        {/* <ProductsTneed></ProductsTneed>
                        <ProductsTneed></ProductsTneed>
                        <ProductsTneed></ProductsTneed> */}
                    </Products>
                </RowProducts>
            </ContainerFluid>
        </PortfolioImage>
    </>
)


export default PortfolioTneed