import React from "react";
import { Router, Switch, Route } from "react-router-dom";
import history from "src/utils/history";
import HomePage from "src/pages/Home";
import ContactUs from "src/pages/contact-us";
import MainCareer from 'src/pages/career';
import AboutUs from 'src/pages/about-us';
import Header from "src/components/Header";
import Footer from "src/components/Footer";
import Designerpage from 'src/pages/career/designer';
import MarketingPage from 'src/pages/career/marketing';
import Backendpage from 'src/pages/career/backend';
import SoftwarePage from 'src/pages/career/software'

const Routers: React.FC = () => (
  <Router history={history}>
    <Header></Header>
    <Switch>
      <Route path="/" exact component={HomePage} />
      <Route path="/contact-us" exact component={ContactUs} />
      <Route path="/career" exact component={MainCareer} />
      <Route path="/career/designer" exact component={Designerpage} />
      <Route path="/career/marketing" exact component={MarketingPage} />
      <Route path="/career/Backend-developer" exact component={Backendpage} />
      <Route path="/career/software" exact component={SoftwarePage} />
      <Route path="/about-us" exact component={AboutUs} />
      <Route path="*" render={() => <p>404 Page</p>} />
    </Switch>
    <Footer/>
  </Router>
); 

export default Routers;
