import Navbar from "react-bootstrap/Navbar";
import styled from "styled-components";
import { Link } from "react-router-dom";


export const MenuNav = styled.div`
  z-index: 1;
  .navbar {
    align-items: end;
    padding: 0px;
  }
`;
export const TneedNavbar = styled(Navbar)`
  overflow: hidden;
  .hidden {
    right: -65%;
    @media (max-width: 768px) {
      right: -20vw;
    }
    @media (max-width: 580px) {
      right: -40vw;
    }
  }
  .show {
    right: 100px;
    @media (max-width: 768px) {
      right: 0px;
    }
  }
  .navbar-toggler {
    border: none;
    outline: none;
    padding: 0;
    --max-size: 52;
    --min-size: 40;
    width: var(--responsive);
    height: var(--responsive);
    .navbar-collapse {
      flex-basis: 50%;
      flex-grow: inherit;
    }
    .responsive-navbar-nav {
      display: none;
    }
  }
`;
export const LogoTneed = styled(Navbar.Brand)`
  --max-size: 65;
  --min-size: 50;
  width: var(--responsive);
  height: calc(var(--responsive)+ 26);
  cursor: pointer;
  @media (max-width : 567px ){
    width: 52px ;
    height: 72px ;
  }
`;
export const NavbarImage = styled.img`
  width: 100%;
  height: 100%;
`;
export const NavbarStyle = styled.ul`
  width: 380px;
  display: flex;
  position: absolute;
  top: 12%;
  justify-content: space-between;
  list-style-type: none;
  padding: 0;
  transition: 0.5s;
  @media (max-width: 768px) {
    right: 0vw;
    position: fixed;
    top: 0px;
    justify-content: end;
    height: 100vh;
    flex-direction: column;
    width: 20vw;
    background-color: white;
  }
  @media (max-width: 580px) {
    width: 40vw;
  }
`;

export const ListNavbar = styled.li`
  color: white;
  font-size: 19px;
  text-align: center;
  @media (max-width: 768px) {
    padding: 15px 0px;
    border-bottom: 2px solid #f05a22;
    margin: 0 10px;
  }
`;
export const ListLink = styled(Link)`
  color: white;
  transition: 0.2s;
  border-bottom: 0px solid #00aac0;
  &:hover {
    color: #ffffff;
    text-decoration: unset;
    border-bottom: 3px solid #00aac0;
    @media (max-width: 768px) {
      color: #260246;
    }
  }
  @media (max-width: 768px) {
    color: #260246;
  }
`;
export const ButtonNavbar = styled.button`
  z-index: 1;
  background-color: transparent;
  cursor: pointer;
  --max-size: 53;
  --min-size: 45;
  width: var(--responsive);
  height: var(--responsive) + 26;
  cursor: pointer;
  outline: 0;
  border: none;
  padding: 0;
  &:focus {
    outline: none;
  }
  @media (max-width : 568px ){
    width: 40px;
    height: 40px;
  }
`;
