import React from "react";
import styled from "styled-components";
import NavbarTneed from "src/components/Navbar"


const HeaderTneed = styled.header`
    position: absolute ;
    width: 100%;
    z-index : 1 ;
    padding: 52px 49px 0px;
    @media (max-width : 1440px ){
        padding: 35px 40px 0px;
    }
    @media (max-width : 500px ){
        padding: 10px 10px 0px;
    }
`

const Header: React.FC = () =>(
    <>
        <HeaderTneed>
            <NavbarTneed /> 
        </HeaderTneed>

    </>
) 
export default Header;
