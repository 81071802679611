import { createGlobalStyle } from 'styled-components/macro';

const GlobalStyle = createGlobalStyle`
    html body{
        margin : 0px ;
        background-color: #260246;
        overflow-X : hidden ;
        transition: 0.5s ;
        right : 0px ;
        position : relative ;
    }
    
    .showNavbar{
      @media ( max-width : 768px ){
        right : 20vw ;
        overflow: hidden;
      }
      @media ( max-width : 580px ){
        right : 40vw ;
      }
    }
    
    * {
        --diff: calc(var(--max-size) - var(--min-size));
        --responsive: calc((var(--min-size) * 1px) + var(--diff) * ((100vw - 420px) / (1200 - 420))); /* Ranges from 421px to 1199px */
      }
    
      *::before {
        --diff: calc(var(--max-size) - var(--min-size));
        --responsive: calc((var(--min-size) * 1px) + var(--diff) * ((100vw - 420px) / (1200 - 420))); /* Ranges from 421px to 1199px */
      }
    
  `;

  export default GlobalStyle;