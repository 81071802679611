import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {MainDiv,Section,CareerList,CareerItem,FigureCaption,ItemName,Figure,MainContainer,CirclePosition} from 'src/pages/career/style'
import {TextHeader,ParagraphText, ButtonAbout} from "src/components/Designs";
import { Container } from "react-bootstrap";
import {Link } from 'react-router-dom';


const MainCareer: React.FC = () => {
  return (
          <Section>
            <MainContainer>
              <Container>
                  <MainDiv className="wow slideInDown">
                    <CirclePosition>
                      <TextHeader>Work with us</TextHeader>
                    </CirclePosition>

                        <ParagraphText>
                          We’re looking for people to join the team who are as excited as we are to help build the platform that empowers the future generation of creators to be successful online.
                        </ParagraphText>
                  </MainDiv>
                        <CareerList>
                        <CareerItem className="wow zoomIn" >
                          <Figure >
                            <ItemName>UI/UX Designer</ItemName>
                            <FigureCaption>Remote</FigureCaption>
                          </Figure>
                          <Link to="/career/designer">
                          <ButtonAbout>Apply</ButtonAbout>
                          </Link>
                            
                        </CareerItem>
                        <CareerItem className="wow zoomIn">
                          <Figure >
                            <ItemName>Frontend Software Engineer</ItemName>
                            <FigureCaption>Remote</FigureCaption>
                          </Figure>
                          <Link to="/career/software">
                          <ButtonAbout>Apply</ButtonAbout>
                          </Link>
                        
                        </CareerItem>
                        <CareerItem className="wow zoomIn">
                          <Figure>
                            <ItemName>Marketing Manager</ItemName>
                            <FigureCaption>Remote</FigureCaption>
                          </Figure>
                          <Link to="/career/marketing">
                          <ButtonAbout>Apply</ButtonAbout>
                          </Link>
                          
                        </CareerItem>
                        <CareerItem className="any-border wow zoomIn">
                          <Figure >
                            <ItemName>Backend Software Engineer</ItemName>
                            <FigureCaption>Remote</FigureCaption>
                          </Figure>
                          <Link to="/career/Backend-developer">
                          <ButtonAbout>Apply</ButtonAbout>
                          </Link>
                          
                        </CareerItem>
                    </CareerList>
              </Container>
 
            </MainContainer>
             
          </Section>
  );
};
export default MainCareer;
