import React from "react";
import {
  FullScreen,
  Section,
  Span,
  Div,
  DutyList,
  ListItem,
  JobCircle
} from "src/pages/career/style";
import { ParagraphText, TextHeader, ButtonAbout } from "src/components/Designs";
import Example from "src/components/modal/index";
import { Container } from "react-bootstrap";

const BackendPage: React.FC = () => {
  return (
    <>
      <Section>
        <FullScreen>
          <Container>
            <JobCircle>
              <Div>
                <TextHeader>Backend engineering</TextHeader>
              </Div>
            </JobCircle>
            
            <Div>
              <ParagraphText>
                OldRobo has a positive, diverse, and supportive culture. we look
                for people who are curious, inventive, and work
              </ParagraphText>
              <ParagraphText>
                to be a little better every single day. In our work together we
                aim to be smart, humble, hardworking and, above all,
              </ParagraphText>
              <ParagraphText>
                collaborative. If this sounds like a good fit for you, why not
                say hello?
              </ParagraphText>
            </Div>

            <Div>
              <Span>Senior Software Engineer, Backend</Span>
              <ParagraphText>
                OldRobo is looking for Senior Backend Engineers to build
                features, design and implement API methods, and improve the
                performance and reliability of our systems as we rapidly scale
                our product and organization. We have backend engineers in
                almost every team across Slack, and in that role, you’ll be
                making some of the most significant decisions for the company.
                We build our app using reliable tools that our team knows and
                trusts, including PHP/Hack, Java, MySQL, and Linux. Expertise in
                these areas is a huge plus, but having familiarity with other
                common web languages (such as Python or Ruby) and other
                relational databases are excellent substitutes. We’re a
                collaborative team that genuinely enjoys working together with a
                cross-functional team of product managers, designers, frontend,
                iOS, Android, automation, and quality engineers to make OldRobo
                a better product. We are looking for engineers that understand
                that simplicity and reliability are aspects of a system to be
                carefully calculated with every decision made.
              </ParagraphText>
            </Div>
            <Div>
              <Span>What You Will Be Doing</Span>
              <DutyList>
                <ListItem>
                  <ParagraphText>
                    You’ll brainstorm with Product Managers, Designers, and
                    Frontend Engineers to conceptualize and build new features
                    for our large (and growing!) user base.
                  </ParagraphText>
                </ListItem>
                <ListItem>
                  <ParagraphText>
                    You’ll produce high-quality results by leading or
                    contributing heavily to large cross-functional projects that
                    have a significant impact on the business.
                  </ParagraphText>
                </ListItem>
                <ListItem>
                  <ParagraphText>
                    You’ll actively own features or systems and define their
                    long-term health, while also improving the health of
                    surrounding systems.
                  </ParagraphText>
                </ListItem>
                <ListItem>
                  <ParagraphText>
                    You’ll assist our skilled support team and operations team
                    in triaging and resolving production issues.
                  </ParagraphText>
                </ListItem>
                <ListItem>
                  <ParagraphText>
                    You’ll mentor other engineers and deeply review code.
                  </ParagraphText>
                </ListItem>
                <ListItem>
                  <ParagraphText>
                    You’ll improve engineering standards, tooling, and
                    processes.
                  </ParagraphText>
                </ListItem>
              </DutyList>
            </Div>
            <Div>
              <Span>What you should have</Span>
              <DutyList>
                <ListItem>
                  <ParagraphText>
                    You’ve been building web applications professionally for 3+
                    years.
                  </ParagraphText>
                </ListItem>
                <ListItem>
                  <ParagraphText>
                    You have experience with functional or imperative
                    programming languages -- e.g., PHP, Python, Ruby, Go, C, or
                    Java.
                  </ParagraphText>
                </ListItem>
                <ListItem>
                  <ParagraphText>
                    You can lead technical architecture discussions and help
                    drive technical decisions within your team.
                  </ParagraphText>
                </ListItem>
                <ListItem>
                  <ParagraphText>
                    You write an understandable, testable code with an eye
                    towards maintainability.
                  </ParagraphText>
                </ListItem>
                <ListItem>
                  <ParagraphText>
                    You are a strong communicator. Explaining complex technical
                    concepts to designers, support, and other engineers is no
                    problem for you.
                  </ParagraphText>
                </ListItem>
                <ListItem>
                  <ParagraphText>
                    You have a bachelor’s degree in Computer Science,
                    Engineering or related field, or equivalent training,
                    fellowship, or work experience.
                  </ParagraphText>
                </ListItem>
                <ListItem>
                  <ParagraphText>
                    You possess strong computer science fundamentals: data
                    structures, algorithms, programming languages, distributed
                    systems, and information retrieval.
                  </ParagraphText>
                </ListItem>
              </DutyList>
            </Div>
            <Div>
              <Example />
            </Div>
          </Container>
        </FullScreen>
      </Section>
    </>
  );
};

export default BackendPage;
