import React, { useState } from "react";
import { ButtonAbout } from "src/components/Designs";
import Modal from "react-bootstrap/Modal";
import { Col, Form } from "react-bootstrap";
import {
  ApplySubmit,
  FormControl,
  FormInput,
  FormRow,
  FormGroup,
  File,
  FileDiv,
  Div,
  NewForm,
  Header,
  Button,
  Spans
} from "src/components/modal/style";
import CountryData from "src/country/index";
import LanguageData from "src/language/index";

const Example = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <ButtonAbout className="m-0" onClick={handleShow}>
        Apply on TNEED
      </ButtonAbout>
      <Modal show={show} onHide={handleClose} size="lg">
        <Header>
         <Button className="close" type="button" onClick={handleClose}>
         <Spans aria-hidden="true">×</Spans>
        </Button> 

        </Header>
        <Modal.Body>
        <NewForm>
          <FormRow>
            <FormGroup as={Col} controlId="">
              <Form.Label>Name</Form.Label>
              <FormInput
                type="text"
                name="name"
                placeholder="Enter your name"
              />
            </FormGroup>

            <FormGroup as={Col} controlId="" className="m-0">
              <Form.Label>Phone Number</Form.Label>
              <FormInput
                type=""
                name="number"
                placeholder="+1 (418) 264-8977"
              />
            </FormGroup>
          </FormRow>

          <FormRow>
            <FormGroup as={Col} controlId="">
              <Form.Label>Country</Form.Label>
              <FormControl as="select">
                <CountryData />
              </FormControl>
            </FormGroup>
            <FormGroup as={Col} controlId="" className="m-0">
              <Form.Label>Language</Form.Label>
              <FormControl as="select">
                <LanguageData />
              </FormControl>
            </FormGroup>
          </FormRow>

          <FormRow>
            <FormGroup as={Col} controlId="">
              <Form.Label>Email Addres</Form.Label>
              <FormInput type="email" placeholder="Enter email" />
            </FormGroup>

            <FormGroup as={Col} controlId="" className="m-0">
              <Form.Label>Resume</Form.Label>
              <Div>
                <FileDiv>
                  <File className="fas fa-paperclip"></File>
                  <FormInput type="file" className="d-none" />
                </FileDiv>
              </Div>
            </FormGroup>
          </FormRow>
          <FormRow>
            <FormGroup as={Col} controlId="">
              <ButtonAbout type="submit" onClick={handleClose}>Submit</ButtonAbout>
            </FormGroup>
          </FormRow>
        </NewForm>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Example;
