import styled from "styled-components";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export const IntroductionImage = styled.div`
  background-image: url(./assets/images/Introduction.png);
  background-size: contain ;
  width: 100%;
  height: 100vh;
  background-position: right;
  background-repeat: no-repeat;
  display: flex;
  @media (max-width : 1026px) {
    height: 60vh;
    background-position: right bottom;
  }
  @media (max-width: 680px){
    .row{
        padding-left : 0px ;
        max-width: 387px;
        margin: auto;
    }
  }
  @media  (orientation: landscape) {
    height: 100vh;
  }
`
export const HeaderIntroduction = styled.div`
    margin : auto 0px ;
    padding: 10px ;
    ::before{
        content: "";
        position: absolute;
        --max-size: 47;
        --min-size: 30;
        width: var(--responsive);
        height:  var(--responsive);
        top: 0;
        left: 10px;
        background-color: #F05A22;
        border-radius: 60px
        @media( max-width : 667px ){
            --max-size: 70;
            --min-size: 40;
            left: 95px;
        }
        @media( max-width : 414px ){
            --max-size: 70;
            --min-size: 40;
            left: 111px;
        }
        @media( max-width : 375px ){
            left: 95px;
        }
        @media( max-width : 360px ){
            left: 85px;
        }
        @media( max-width : 320px ){
            left: 70px;
        }
    }
`
export const HeaderText = styled.h1`
    font-weight: 100;
    letter-spacing: 0.91px;
    color: #FFFFFF;
    position: relative;
    --max-size: 20;
    --min-size: 10;
    font-size: var(--responsive);
    @media ( max-width : 680px ){
        --max-size: 30;
        --min-size: 20;
    }
`
export const ParagraphIntroduction = styled.div`
    font-weight: bold;
    display: flex;
    flex-direction: column;
`
export const ParagraphSpan = styled.span`
    color: white;
    --max-size: 30;
    --min-size: 10;
    font-size: var(--responsive);
    @media ( max-width : 680px ){
        --max-size: 45;
        --min-size: 20;
    }
`

export const StyleRow = styled(Row)`
    padding-top: 17%;
    padding-left: 10%;
    @media (max-width : 1026px) {
        padding-top: 24%;   
    }
    @media (max-width : 992px) and (orientation : landscape) {
        padding-top: 20%;   
    }

    @media (max-width : 576px) {
        padding-top: 30%;   
    }
    @media (max-width : 667px) and (min-width: 665px) {
        padding-top: 15%;   
    }
    @media (max-width : 568px) and (min-width: 567px) {
        padding-top: 17%;   
    }
`;

export const StyleColumn = styled(Col)`
    @media (max-width : 680px ){
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center; 
    }

`