import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Logo from "src/components/Logo";
import Image from "public/assets/images/menu.svg";
import {
  MenuNav,
  TneedNavbar,
  LogoTneed,
  NavbarImage,
  NavbarStyle,
  ListNavbar,
  ListLink,
  ButtonNavbar,
} from "src/components/Navbar/style";

const NavbarTneed: React.FC = () => {
  const [showMenu, updateShowMenu] = useState("hidden");

  const navbarMenu = (event: React.MouseEvent) => {
    event.stopPropagation();
    updateShowMenu(showMenu === "hidden" ? "show" : "hidden");
    showMenu === "hidden"
      ? document.body.classList.add("showNavbar")
      : document.body.classList.remove("showNavbar");
  };

  const showMenuNavbar = () => {
    updateShowMenu("hidden");
    document.body.classList.remove("showNavbar");
  };

  useEffect(() => {
    window.addEventListener("click", showMenuNavbar);
    return function cleanup() {
      window.removeEventListener("click", showMenuNavbar);
    };
  }, []);

  return (
    <MenuNav>
      <TneedNavbar collapseOnSelect expand={false}>
        <Link to="/">
          <LogoTneed>
            <Logo />
          </LogoTneed>
        </Link>
        <NavbarStyle className={showMenu}>
          <ListNavbar>
            <ListLink to="/"> Home</ListLink>
          </ListNavbar>
          <ListNavbar>
            <ListLink to="/career"> Jobs</ListLink>
          </ListNavbar>
          <ListNavbar>
            <ListLink to="/about-us"> About Us</ListLink>
          </ListNavbar>
          <ListNavbar>
            <ListLink to="/contact-us"> Contact</ListLink>
          </ListNavbar>
        </NavbarStyle>

        <ButtonNavbar onClick={navbarMenu}>
          <NavbarImage src={Image} />
        </ButtonNavbar>
      </TneedNavbar>
    </MenuNav>
  );
};

export default NavbarTneed;
