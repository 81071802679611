import { createBrowserHistory } from "history";

const history = createBrowserHistory();

export const goToPath = (path: string, obj = {}) => {
  history.push({
    pathname: path,
    state: obj
  });
};

export default history;
