import React from "react";
import styled from "styled-components";
import LogoImage from "public/assets/images/Logo.svg"


const ImageLogo = styled.img`
    width:100%;
    height:100%;
`
const Logo : React.FC = () => <ImageLogo src={LogoImage} />

export default Logo;