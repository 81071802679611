import React from "react";
import language from 'src/language/language';

const LanguageData:React.FC = () => {
    return ( 
       <>
          {language.map(x => <option>{x.name}</option>)}
       </>
    );
};
  
  export default LanguageData;