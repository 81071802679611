import React from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";

import {
  ParentButton,
  TransparentButton,
  ButtonAbout,
} from "src/components/Designs";
import {
  IntroductionImage,
  HeaderIntroduction,
  HeaderText,
  ParagraphIntroduction,
  ParagraphSpan,
  StyleRow,
  StyleColumn,
} from "src/components/Introduction/style";

const IntroductionTneed: React.FC = () => {
  return (
    <IntroductionImage className="wow fadeInRight animated">
      <Container fluid={true}>
        <StyleRow>
          <StyleColumn
            className="wow fadeInLeft animated"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
          >
            <HeaderIntroduction>
              <HeaderText>Ahead of others</HeaderText>
            </HeaderIntroduction>
            <ParagraphIntroduction>
              <ParagraphSpan>WE DEVELOP</ParagraphSpan>
              <ParagraphSpan> TECHNOLOGIES YOU NEED</ParagraphSpan>
            </ParagraphIntroduction>
            <ParentButton>
              <Link to="/career">
                <TransparentButton className="extraSmallFont">
                  Join Us Now
                </TransparentButton>
              </Link>
              <Link to="/about-us">
                <ButtonAbout className="extraSmallFont">About Us</ButtonAbout>
              </Link>
            </ParentButton>
          </StyleColumn>
          <Col xs={5} md={5}></Col>
        </StyleRow>
      </Container>
    </IntroductionImage>
  );
};
export default IntroductionTneed;
